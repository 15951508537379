<template>
  <div class="page1">
    <div class="content">
      <div class="flexAround">
        <div class="overviewBox background1">
          <div class="leftShow">
            <img src="../../assets/img/park.png" alt="" />
          </div>
          <div class="rightShow">
            <div class="top">
              道路总数
              <el-tooltip slot="left" placement="top">
                <div slot="content">
                  道路总数：“商用+试运行状态”下的停道路总数<br />泊位总数：“商用+试运行状态”下的停道路泊位总数
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
            <div class="center">{{ parkCount }} <span class="unit">个</span></div>
            <div class="bottom">
              泊位总数<span class="num">{{ berthCount }}个</span>
            </div>
          </div>
        </div>
        <div class="overviewBox background2">
          <div class="leftShow">
            <img src="../../assets/img/park1.png" alt="" />
          </div>
          <div class="rightShow">
            <div class="top">
              今日停车记录数
              <!--<el-tooltip slot="left" content="今日停车记录数：出场时间从今日0:00至当前时间的停车记录数量 日均停车记录：先算出车场每一天的出场停车记录总数，再用近7天的每天停车记录之和÷7" placement="top">-->
              <!--<i class="el-icon-question"></i>-->
              <!--</el-tooltip>-->
              <el-tooltip slot="left" placement="top">
                <div slot="content">
                  今日停车记录数：入场时间从今日0:00至当前时间的停车记录数量<br />日均停车记录：先算出车场每一天的入场停车记录总数，再用近7天的每天停车记录之和÷7
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
            <div class="center">{{ exitCount }} <span class="unit">个</span></div>
            <div class="bottom">
              日均停车记录数<span class="num">{{ averageExitCount }}个</span>
            </div>
          </div>
        </div>
        <div class="overviewBox background3">
          <div class="leftShow">
            <img src="../../assets/img/money.png" alt="" />
          </div>
          <div class="rightShow">
            <div class="top">
              今日收入总额
              <el-tooltip slot="left" placement="top">
                <div slot="content">
                  今日收入总额：收款时间为今日0:00至当前时间的总额<br />日均收入总额：先算出车场每一天收入总额，再用近7天的每天收入总额之和÷7
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
            <div class="center">{{ actualPay / 100 }} <span class="unit">元</span></div>
            <div class="bottom">
              日均收入总额<span class="num">{{ averageActualPay | moneyHandling }}元</span>
            </div>
          </div>
        </div>
        <div class="overviewBox background4">
          <div class="leftShow">
            <img src="../../assets/img/berth.png" alt="" />
          </div>
          <div class="rightShow">
            <div class="top">
              日均时长泊位利用率
              <el-tooltip slot="left" placement="top">
                <div slot="content">
                  日均泊位时长利用率：近7天内，每天泊位时长利用率之和÷7。每天的泊位时长利用率=当天出场车辆的停车时长之和÷当天的泊位数÷24。<br />日均泊位周转次数：近7天内，每天泊位周转次数之和÷7。每天的泊位周转次数=当天出场车辆的停车记录之和÷当天的泊位数。
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
            <div class="center">{{ parkTimeRatio }} <span class="unit">%</span></div>
            <div class="bottom">
              日均泊位周转次数<span class="num">{{ averageBerthTurnover }}次</span>
            </div>
          </div>
        </div>
      </div>
      <div class="list">
        <el-row>
          <el-col :span="16" class="colLeft">
            <div class="divSize">
              <div class="divIcon"></div>
              <h2>
                停车收入概览
                <el-tooltip
                  slot="left"
                  content="停车收入概览：按支付时间统计实收，指每个区域，支付时间在最近7天，所有车辆实收数据。"
                  placement="top"
                >
                  <i class="el-icon-question" style="color: #d0d0d0"></i>
                </el-tooltip>
              </h2>
              <parkingRevenue></parkingRevenue>
            </div>
          </el-col>
          <el-col :span="8" class="colRight">
            <div class="divSize">
              <div class="divIcon"></div>
              <h2 class="top5">
                停车收入top5
                <el-tooltip
                  slot="left"
                  content="停车收入top5：统计每个道路最近7天支付的实收金额，取前5展示"
                  placement="top"
                >
                  <i class="el-icon-question" style="color: #d0d0d0"></i>
                </el-tooltip>
              </h2>
              <el-row v-for="(park, index) in parkRevenueTopList" :key="index" class="row">
                <el-col class="col">
                  <span
                    class="indexNumber"
                    :class="[index + 1 > 3 ? 'bottomNumber' : 'topNumber']"
                    >{{ index + 1 }}</span
                  >
                  <span class="item">{{ park.parkname }}</span>
                  <span class="itemMoney">{{ (park.actualpay / 100).toFixed(2) }}</span>
                  <!-- <span class="itemMoney" v-if="park.exitPayRatio < 1">{{(park.exitPayRatio * 100).toFixed(2)}}%</span> -->
                  <!-- <span class="itemMoney" v-if="park.exitPayRatio >= 1">100%</span> -->
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="list">
        <el-row>
          <el-col :span="16" class="colLeft">
            <div class="divSize">
              <div class="divIcon"></div>
              <h2>
                停车记录概览
                <el-tooltip
                  slot="left"
                  content="停车记录概览：按入场时间统计每个地区最近7天停车记录数。"
                  placement="top"
                >
                  <i class="el-icon-question" style="color: #d0d0d0"></i>
                </el-tooltip>
              </h2>
              <!-- <parkbarChart :parkRecord="parkRecord"></parkbarChart> -->
              <parkingRecord></parkingRecord>
            </div>
          </el-col>
          <el-col :span="8" class="colRight">
            <div class="divSize">
              <div class="divIcon"></div>
              <h2 class="top5">
                停车记录top5
                <el-tooltip
                  slot="left"
                  content="停车记录top5：按入场时间统计每个道路最近7天停车记录，取前5展示"
                  placement="top"
                >
                  <i class="el-icon-question" style="color: #d0d0d0"></i>
                </el-tooltip>
              </h2>
              <el-row v-for="(park, index) in parkRecordList" :key="index" class="row">
                <el-col class="col">
                  <span
                    class="indexNumber"
                    :class="[index + 1 > 3 ? 'bottomNumber' : 'topNumber']"
                    >{{ index + 1 }}</span
                  >
                  <span class="item">{{ park.parkname }}</span>
                  <span class="itemMoney">{{ park.parkingCnt }}</span>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
//  import flex from "./flex";
import barchart from "./barchart";
import myComponent from "@/components/autocomplete/myautoComponent";
import parkbarChart from "@/components/parkbarChart/parkbarChart";
import parkingRevenue from "./parkingRevenue";
import parkingRecord from "./parkingRecord";
export default {
  components: {
    //      flex,
    // barchart,
    // parkbarChart,
    parkingRevenue,
    parkingRecord,
  },
  data() {
    return {
      type3: "c3",
      parkNum: {},
      exitCount: "", // 今日停车记录数
      actualPay: "", // 今日实收总额
      parkCount: "",
      berthCount: "",
      parkTimeRatio: "", // 日均泊位时长利用率
      averageExitCount: "", // 日均停车记录数
      averageActualPay: "", // 日均实收总额
      averageBerthTurnover: "", // 日均泊位周转次数
      parkMoneyList: [], // 停车实缴率Top5
      parkRecordList: [], // 停车记录TOP5
      parkRevenueTopList: [], // 停车收入TOP5
      parkRecord: [],
      durationAnalysis: [
        {
          areaTypeName: "唐山市",
          money: 3,
          money1: 20,
        },
        {
          areaTypeName: "保定市",
          money: 30,
          money1: 10,
        },
        {
          areaTypeName: "廊坊市",
          money: 10,
          money1: 25,
        },
        {
          areaTypeName: "张家口",
          money: 10,
          money1: 25,
        },
      ],
      //        units: [
      //          {
      //            n: "133",
      //            name: "车场总数",
      //            name1: "泊位总数",
      //            outStyle: {
      //              'background': 'linear-gradient(270deg,#ffc774, #ff854a)',
      //              'box-shadow': '2px 5px 10px 0px rgba(255,183,125,0.51)'
      //            },
      //            img: require('../../assets/img/park.png'),
      //            unit: "个",
      //            content: '车场总数：商用状态下的停车场总数  泊位总数：商用状态下的停车场泊位总数',
      //          }
      //        ],
    };
  },
  methods: {
    // 车场总数，泊位总数
    getParkBuildInfo() {
      // let url = '/acb/2.0/parkReport/parkBuildInfo';
      let url = "/acb/2.0/dataAnalysisOverView/getParkAndBerthCount";
      this.$axios
        .get(url, {
          data: {
            dataSource: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkCount = res.value.parkCount;
            this.berthCount = res.value.berthCount;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    getTodayParkingInfo() {
      let url = "/acb/2.0/parkReport/todayParkingInfo";
      this.$axios
        .get(url, {
          data: {
            dataSource: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.exitCount = res.value.exitCount;
            this.actualPay = res.value.actualPay;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    // 停车记录数替换
    getParkingInfo() {
      let url = "/acb/2.0/inRoadMain/parkRecordStatisticsInfo";
      this.$axios
        .get(url, {
          data: {
            dataSource: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.exitCount = res.value.todayParkRecordCount;
            this.averageExitCount = res.value.beforeParkRecordCount;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    //  泊位周转率
    getBerlvu() {
      let url = "/acb/2.0/inRoadMain/berthStatisticsInfo";
      this.$axios
        .get(url, {
          data: {
            dataSource: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkTimeRatio = res.value.averageDailyBerthUtilizationRate;
            this.averageBerthTurnover = res.value.averageDailyBerthTurnover;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    // 各区域停车情况
    getQueryRecentDaysAmountByArea() {
      let url = "/acb/2.0/parkReport/queryRecentDaysAmountByArea";
      this.$axios
        .get(url, {
          data: {
            dataSource: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.parkTimeRatio = res.value.amount.parkTimeRatio
            // this.averageExitCount = res.value.amount.averageExitCount
            this.averageActualPay = res.value.amount.averageActualPay;
            // this.averageBerthTurnover = res.value.amount.averageBerthTurnover
            this.parkRecord = res.value.list;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    // 停车实缴率TOP5
    getParkMoney() {
      let url = "/acb/2.0/parkReport/queryRecentDaysExitPayRatioTopN";
      this.$axios
        .get(url, {
          data: {
            dataSource: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkMoneyList = res.value;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    // 停车收入TOP5
    handleGetParkRevenueTop() {
      let url = "/acb/2.0/inRoadMain/parkIncomeTop";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value || [];
          this.parkRevenueTopList = res.value.splice(0, 5);
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },
    // 停车记录TOP5
    getParkRecord() {
      // let url = '/acb/2.0/parkReport/queryRecentDaysExitCountTopN';
      let url = "/acb/2.0/inRoadMain/parkRecordTop";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value || [];
          this.parkRecordList = res.value.splice(0, 5);
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getParkBuildInfo();
      this.getTodayParkingInfo();
      this.getQueryRecentDaysAmountByArea();
      // this.getParkMoney()
      this.handleGetParkRevenueTop(); // 停车收入top
      this.getParkRecord();
      this.getParkingInfo();
      this.getBerlvu();
    });
  },
  created() {},
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.flexAround {
  display: flex;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}

.units {
  margin: 10px;
}

.content {
  background: #F0F6F9;
  padding: 20px 0px;
}

.list {
  margin-top: 20px;

  .el-col {
    box-sizing: border-box;
    padding-bottom: 18px;
    height: 307px;
  }

  .colLeft {
    padding-right: 10px;
    padding-left: 15px;
    min-width: 560px;
  }

  .colRight {
    padding-right: 15px;
    padding-left: 10px;
  }

  .divSize {
    height: 287px;
    border-radius: 6px;
    padding: 10px 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }

  h2 {
    display: inline-block;
    /* height 39px */
    /* line-height 39px */
    margin-bottom: 10px;
    font-size: 14px;
    text-align: left;
    color: #2f2f51;
    padding-left: 10px;
  }

  .top5 {
    /* margin-bottom 10px */
  }
}

.row {
  width: 100%;
  color: #333333;
  margin-left: 15px;

  .col {
    width: 100%;
    height: 50px;
    line-height: 50px;

    .indexNumber {
      display: inline-block;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      margin-right: 10px;
      font-size: 14px;
      border-radius: 50%;
    }

    .topNumber {
      background-color: #F8921C;
      color: white;
    }

    .bottomNumber {
      color: rgba(0, 0, 0, 0.65);
      background-color: #FFE7AD;
    }

    .item {
      position: absolute;
      left: 40px;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .itemMoney {
    float: right;
    font-size: 15px;
    margin-right: 20px;
  }
}

.divIcon {
  display: inline-block;
  position: absolute;
  width: 4px;
  height: 15px;
  background: #0c66ff;
}

.overviewBox {
  display: inline-block;
  width: 22.5%;
  height: 106px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  color: #fff;

  .leftShow {
    float: left;
    display: inline-block;
    margin: 30px 20px 0px 20px;
  }

  .rightShow {
    margin-left: 20px;
    margin-top: 14px;
    font-size: 14px;
  }

  img {
    width: 46px;
    height: 46px;
  }

  .top {
    margin-bottom: 8px;
  }

  .center {
    font-size: 22px;
    margin-bottom: 14px;

    .unit {
      font-size: 14px;
    }
  }
}

.num {
  margin-left: 5px;
}

.background1 {
  background: linear-gradient(270deg, #ffc774, #ff854a);
  box-shadow: 2px 5px 10px 0px rgba(255, 183, 125, 0.51);
}

.background2 {
  background: linear-gradient(270deg, #00b4ff, #3d65ff);
  box-shadow: 2px 5px 10px 0px rgba(127, 166, 255, 0.51);
}

.background3 {
  background: linear-gradient(270deg, #00e7ef, #00bfc0);
  box-shadow: 2px 5px 10px 0px rgba(76, 223, 255, 0.51);
}

.background4 {
  background: linear-gradient(270deg, #00e888, #15d770);
  box-shadow: 2px 5px 10px 0px rgba(76, 255, 120, 0.51);
}
</style>
