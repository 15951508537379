<template>
  <div class="page">
    <!--<div class="head">单位：元</div>-->
    <div id="chart" class="cylindricalchart"></div>
  </div>
</template>
<script>
export default {
  props: {
    parkRecord: {
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      areaName: [],
      seriesValue3: [
        {
          name: '应收金额',
          type: 'bar',
          data: [20, 80],
          barWidth: '16',
          itemStyle: {
            normal: {
              color: '#FF9845',
              shadowBlur: 2,
              shadowColor: 'rgba(255,156,135,0.50)',
              shadowOffsetX: 2,
              shadowOffsetY: 2,
              barBorderRadius: [4, 4, 0, 0]
            }
          }
        },
        {
          name: '实收金额',
          type: 'bar',
          data: [100, 50],
          barWidth: '16',
          itemStyle: {
            normal: {
              color: '#64C8BC',
              shadowBlur: 2,
              shadowColor: 'rgba(92,237,220,0.49)',
              shadowOffsetX: 2,
              shadowOffsetY: 2,
              barBorderRadius: [4, 4, 0, 0],
//              label: {
//                show: true,		// 开启显示
//                position: 'top',	// 在上方显示
//                textStyle: {	    // 数值样式
//                  color: 'blank',
//                  fontSize: 16
//                }
//              }
            }
          }
        }
      ]
    }
  },
  watch: {
    // 夜间停车记录趋势
    parkRecord: {
      handler (value) {
        this.dateList = [];
        this.seriesValue3[0].data = [];
        this.seriesValue3[1].data = [];
        for (var i = 0; i < value.length; i++) {
          this.seriesValue3[0].data.push(value[i].exitShouldPay / 100);
          this.seriesValue3[1].data.push(value[i].exitActualPay / 100);
          this.areaName.push(value[i].areaName);
        }

        if (this.areaName.length > 7) {
          this.drawGraph(true);
        } else {
          this.drawGraph(false);
        }
      },
      deep: true
    }
  },
  methods: {
    drawGraph (show) {
      let option = {
        title: {
          text: '单位：元',
          color: '#9AA4B3',
          textStyle: {
            fontSize: 12,
            color: '#9aa4b3'
          }
        },
        grid: {
        },
        dataZoom: [{
          startValue: 0, // 从头开始
          endValue: 6, // 一次性展示7个
          type: 'slider',
          show: show,
          xAxisIndex: [0],
          handleSize: 0, // 滑动条的 左右2个滑动条的大小
          height: 8, // 组件高度
          bottom: 20, // 下边的距离
//          borderColor: "#ccc",
          fillerColor: 'rgba(0, 0, 0, 0.3)',
          borderColor: "white",
//          backgroundColor: 'rgba(0, 0, 0, 0.3)', // 两边未选中的滑动条区域的颜色
          showDataShadow: false, // 是否显示数据阴影 默认auto
          showDetail: false, // 即拖拽时候是否显示详细数值信息 默认true
          realtime: true, // 是否实时更新
          filterMode: 'filter'
        }],
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow',
            lineStyle: {
              color: '#FF9845'
            },
            shadowStyle: {
              opacity: 0.15,
              width: 1,
              color: '#FF9845'
            }
          }
        },
        toolbox: {
        },
        legend: {
          left: 'right',
          itemWidth: 10,
          itemHeight: 5,
          textStyle: {
            color: "#9AA4B3",
            fontSize: 12
          },
          data: ['应收金额', '实收金额']
        },
        xAxis: [
          {
            type: 'category',
            data: this.areaName,
            axisTick: {
              show: false
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#9AA4B3'
              }
            },
            axisLabel: {
              interval: 0,
//              formatter: function(value) {
//                return value.split("").join("\n");
//              }
            },
            // 展示网格线
            splitLine: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisTick: {
              show: false
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#9AA4B3'
              }
            },
            // 展示网格线
            splitLine: {
              show: false
            }
          }
        ],
        series: this.seriesValue3
      };
      let circularChart
      circularChart = this.$echarts.init(document.getElementById('chart'));
      circularChart.setOption(option);
    }
  },
  mounted() {
    this.drawGraph()
    // console.log(this.parkRecord, 'nightParktableData')
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
  .page
    color #9aa4b3;
  #chart
    color #9aa4b3;
  .head
    margin 10px 0px
  .cylindricalchart
    width 100%
    height 277px
    overflow hidden
</style>

